export type ProfilesDataType = {
  student_number: number;
  name: string;
  name_en: string;
  sentence: string;
  email: string;
  instagram?: string;
  photopolio_link?: string;
  plant_name: string;
  plant_category: string;
  concept: string;
  experience: string;
  challenge: string;
  story: string;
};

export const PROFILES = [
  {
    student_number: 2021151037,
    name: '이승은',
    name_en: 'Seung-Eun Lee',
    sentence: '반갑습니다. 디자이너 이승은입니다.',
    email: 'min1100777@gmail.com',
    plant_name: '킹낭콩',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 아이디어나 컨셉은 개인 소유형 퍼스널 모빌리티와 대중교통의 연계 입니다. 최근 사회에서 문제가 되고 있는 공유방식의 PM을 소유방식으로 전환함으로써 문제를 해결하고, 소유형으로 전환하였을 때 대중교통과 연계가 쉽게 하여 소유형 PM의 사용성과 편의성을 높이는 것을 중심으로 PM을 들어 올림과 동시에 접히도록하는 접이 방식을 구현하기 위해 노력하였습니다.',
    experience:
      '작품을 통해 전달하고자 하는 메시지나 사용자에게 전달하고자 하는 경험은 접이식 기능을 통해 PM과 대중교통의 연계성을 높여 사용자의 이동 편의성을 높이는 것입니다. 이 경험을 위해 PM의 접이식 방식이 보다 간편하고 쉽게 접히도록 하는 구조를 제안하고자 했습니다.',
    challenge:
      '기존에 없었던 접이 방식을 설계 해야했기 때문에 그 과정이 가장 어려웠습니다. 이를 극복하기 위해 이루고자 하는 목표를 계속 생각해보면서 직접 새로운 구조를 설계하고 모델링을 진행하여 시현해보았습니다. 이러한 노력 덕분에 저희는 최종 접이 방식을 도출했고 대중교통과의 연계성을 높이고자 했던 목표에 도달할 수 있게 되었습니다.',
    story:
      '모빌리티 분야에 장벽이 높았었는데 저보다 이 분야에 해박한 팀원 덕분에 혼자 했었다면 겪었을 어려움을 조금 덜 겪고 정보도 많이 얻어가며 작품을 진행할 수 있었습니다. 이번 프로젝트를 진행하며 모빌리티 제품 제작에 벽이 낮아진 거 같아요 흥미롭고 재미있었습니다.'
  },
  {
    student_number: 2019151007,
    name: '김수현',
    name_en: 'Su-Hyeon Kim',
    sentence: '끝까지 가면 무엇이든 될 수 있다.',
    email: 'ljc8620@naver.com',
    instagram: 'ruhxun_',
    plant_name: '수바리',
    plant_category: '바질',
    concept:
      '제품에 이끼를 활용한다는 게 가장 큰 특징입니다. 기후 문제에 관심이 많아 리서치하던 중 대기오염을 주제로 정하게 되었는데요, 특히 실내 공기질, 사무공간에 대한 개선 방안이 적은 것에 집중했습니다. 기존 공기청정기를 이용하면 주기적으로 교체해야 하는 필터로 인해 환경문제가 더욱 심화될 수 있습니다. 오래 사용하여 먼지가 쌓인 헤파필터는 재활용이 불가능하거든요. 이때 이끼의 존재와 이끼의 탁월한 공기정화 능력을 이용하여 친환경적인 방법으로 실내공기 질을 개선하고자 했습니다. 이끼처럼 살아있는 생물을 제품에 적용한다는 것이 생소할 수 있는데, 가전에 이끼를 자연스럽게 결합하기 위해 노력했습니다.',
    experience:
      '사실 이끼라는 것이 마냥 긍정적인 인식만 존재하지는 않습니다. 아무래도 이끼가 자생하는 환경의 특성상 조금 습하고 그늘진 이미지가 연상되거든요. 하지만 조금만 조사해 보면 이끼는 환경에 매우 도움을 주는 존재이고, 실제로 이끼를 활용한 기술 개발 연구가 최근에 활발히 진행되고 있습니다. 그래서 저희는 이끼에 대한 긍정적인 이미지를 사용자에게 각인시키는 것이 중요하다고 생각했습니다. 따라서 이끼를 활용한 제품개발 및 브랜드 아이덴티티를 함께 개발하였습니다. 이를 통해 일상에서 자연스럽게 이끼를 가까이 경험해 보며 익숙해지도록 유도하고, 친환경적인 공기정화 방식을 굳히고자 했습니다. 단순히 가전제품을 이용한다는 느낌이 아니라, 살아있는 이끼, 친근한 친구를 곁에 두고 싶다는 이미지를 만들고 싶었습니다.',
    challenge:
      '제가 이끼를 키울 줄은 상상도 못 했습니다. 아무래도 그냥 제품을 개발하는 것 보다, 살아있는 식물을 제품에 적용한다는 것이 가장 큰 도전이었습니다. 저희가 제품에 사용하고자 한 서리이끼는 잎에 물을 분사하면 바로 활짝 피어난다는 것이 특징입니다. 외관의 프레임 구조와 이끼 필터가 결합할 수 있는 방식에 대해 많이 고민하고, 실제 서리이끼를 키워보면서 실험을 해보았습니다. 실제로 작품 제작 준비를 하며 수많은 종류의 이끼가 있다는 것을 알았고, 이제는 어느 정도 구별까지 가능한 지경입니다. ',
    story:
      '실제 이끼와 가전제품을 결합한 디자인을 살면서 처음해봤습니다. 설계하는 과정에서 아두이노, dc모터등을 활용하는 방법에 대해 알게 되었고 디자인 외의 다양한 방향으로 많이 배운 것 같습니다. 이러한 과정 덕분에 앞으로 얼마나 더욱 어렵고 힘든 문제에 직면하게 되어도 끝까지 해낼 수 있다고 자신감을 가지게 되었고 성장하게 된 것 같습니다.'
  },
  {
    student_number: 2021151011,
    name: '김이연',
    name_en: 'I-Youn Kim',
    sentence: '사람들은 절 개미라고 부르곤 하죠. ',
    email: 'gimmedus@gmail.com',
    instagram: 'gimgammie.design',
    photopolio_link: 'https://www.behance.net/rladldus1233eb',
    plant_name: 'a perfect match for tomatoes',
    plant_category: '바질',
    concept:
      '제품에 이끼를 활용한다는 게 가장 큰 특징입니다. 기후 문제에 관심이 많아 리서치하던 중 대기오염을 주제로 정하게 되었는데요, 특히 실내 공기질, 사무공간에 대한 개선 방안이 적은 것에 집중했습니다. 기존 공기청정기를 이용하면 주기적으로 교체해야 하는 필터로 인해 환경문제가 더욱 심화될 수 있습니다. 오래 사용하여 먼지가 쌓인 헤파필터는 재활용이 불가능하거든요. 이때 이끼의 존재와 이끼의 탁월한 공기정화 능력을 이용하여 친환경적인 방법으로 실내공기 질을 개선하고자 했습니다. 이끼처럼 살아있는 생물을 제품에 적용한다는 것이 생소할 수 있는데, 가전에 이끼를 자연스럽게 결합하기 위해 노력했습니다.',
    experience:
      '사실 이끼라는 것이 마냥 긍정적인 인식만 존재하지는 않습니다. 아무래도 이끼가 자생하는 환경의 특성상 조금 습하고 그늘진 이미지가 연상되거든요. 하지만 조금만 조사해 보면 이끼는 환경에 매우 도움을 주는 존재이고, 실제로 이끼를 활용한 기술 개발 연구가 최근에 활발히 진행되고 있습니다. 그래서 저희는 이끼에 대한 긍정적인 이미지를 사용자에게 각인시키는 것이 중요하다고 생각했습니다. 따라서 이끼를 활용한 제품개발 및 브랜드 아이덴티티를 함께 개발하였습니다. 이를 통해 일상에서 자연스럽게 이끼를 가까이 경험해 보며 익숙해지도록 유도하고, 친환경적인 공기정화 방식을 굳히고자 했습니다. 단순히 가전제품을 이용한다는 느낌이 아니라, 살아있는 이끼, 친근한 친구를 곁에 두고 싶다는 이미지를 만들고 싶었습니다.',
    challenge:
      '제가 이끼를 키울 줄은 상상도 못 했습니다. 아무래도 그냥 제품을 개발하는 것 보다, 살아있는 식물을 제품에 적용한다는 것이 가장 큰 도전이었습니다. 저희가 제품에 사용하고자 한 서리이끼는 잎에 물을 분사하면 바로 활짝 피어난다는 것이 특징입니다. 외관의 프레임 구조와 이끼 필터가 결합할 수 있는 방식에 대해 많이 고민하고, 실제 서리이끼를 키워보면서 실험을 해보았습니다. 실제로 작품 제작 준비를 하며 수많은 종류의 이끼가 있다는 것을 알았고, 이제는 어느 정도 구별까지 가능한 지경입니다. ',
    story:
      "환경적으로 최근 많이 관심이 집중되고 있는 이끼를 활용해서 실제 크기 의 제품으로까지 개발하는 것이 쉽지는 않았지만, 재밌게 즐길 수 있었기에 무사히 마칠 수 있었습니다. 저희의 작품을 보시면 이끼라는 낯선 소재에 누군가는 재미를, 누군가는 어색함을 느끼실 수 있을 것 같습니다. 하지만 이끼를 통해 제품까지 개발하게 된 배경을 집중해서 봐주신다면 모두 재미있게 작품을 즐기실거라 기대합니다. '낑'을 제작하면서 누군가에게 나의 아이디어를 전달하기 위해 큰 노력을 기울인 경험은 앞으로 저에게 귀중한 자산이 될 것입니다. "
  },
  {
    student_number: 2019151035,
    name: '정해민',
    name_en: 'Hae-Min Jung',
    sentence: '權不十年 花無十一紅',
    email: 'wjdgoals36@gmail.com',
    instagram: 'mean_o0',
    plant_name: '키',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 컨셉은 다이나믹한 경험을 할 수 있는 수상 모빌리티입니다. 다이나믹한 경험은 긍정적이고 문화적인 측면으로 나타날 수 있다고 생각하였습니다. 이를 위해 여러가지 공상과학 영화를 비롯한 다양하게 제작된 수상 모빌리티들에서 아이디어를 얻어 안정적인 경험을 제공할 수 있도록 노력하였습니다. ',
    experience:
      '작품을 통해 전달하고자 하는 메세지와 경험은 자유로움입니다. 비슷한 활동을 진행하게 될 후배들, 타 대학의 유사전공자들 모두 누군가를 돕기위한 주제를 통해 작품을 제작하는 경험이 많아보였고, 그 틀을 깨보는 시도를 통해 다양한 주제를 도전할 수 있는 길을 열어주고자 하였습니다. 그리고 사용자에게도 수중과 수면이라는 공간의 경계를 허물며 더욱 다각도의 경험을 통해 자유로움을 경험할 수 있도록 하는 방법을 제안하고자 하였습니다. ',
    challenge:
      '작품을 만들면서 가장 큰 도전은 상상에서 그치는 것이 아닌, 제품을 현실로 꺼내오는 과정에서오는 한계가 있는 것 이었습니다. 이를 극복하기 위해 저희는 실무에 계신 멘토님을 비롯해, 주변에서 자문을 구할 수 있는 수단과 방법을 가리지 않고 다양한 조언을 얻어가며 작품을 구체화 하는 방법을 선택하였습니다. 이 덕분에 저희는 막막하던 것에 비해 비교적 간단하게나마 실현할 수 있는 방법을 찾아서 제품에 생명을 불어넣을 수 있었습니다.',
    story:
      '이 프로젝트를 진행하면서 제품에 들어가는 부품의 코딩이나 모델링을 하였습니다. 이러한 경험을 통해 저의 디자인 및 엔지니어링 기술에 밑거름이 되었다고 생각합니다.'
  },
  {
    student_number: 2021151014,
    name: '김지민',
    name_en: 'Ji-Min Kim',
    sentence: '가장 깊은 공감은 애정에서 나온다.',
    email: 'zmkk1107@gmail.com',
    instagram: 'dsgnr_zmkk',
    photopolio_link: 'https://zmkk.myportfolio.com',
    plant_name: '콩밥이',
    plant_category: '얼룩강낭콩',
    concept:
      '저희 제품의 주요 컨셉은 배터리 딜리버리 로봇이 배터리를 견인하여 자유롭게 주행하는 것입니다. 늘어나는 전기차 충전 수요만큼의 충전소를 만들게 된다면, 해당 전력을 부담할 수 있는 발전소를 지어야 하며 도시 전기 그리드에도 부담이 됩니다. 이를 해결하기 위해 배터리를 활용하여 전기차의 충전을 지원하고자 했습니다. 전기차 충전 설비가 적은 대형 주차장에서 전기차의 충전이 필요한 경우, 로봇이 배터리를 운반하여 사용자가 지정한 장소에 두고, 사용자가 운반된 배터리의 충전기를 전기차에 꽂아 차량을 충전하는 방식입니다. \n이 방식의 장점은 건물이나 도심에서 분리된 전기 시스템을 사용하기 때문에, 전력 수요의 문제가 해결된다는 점, 노면 상황이 좋지 않은 장소에서도 사용할 수 있다는 점이 있습니다.',
    experience:
      '전기차 충전과 관련된 제약 조건들을 없애고, 도심 전력망에 부담을 줄여야 한다는 환경 문제를 해결하는 등 기존의 고전적인 충전 방식에서 벗어나 더 유연하고 효율적으로 대처할 수 있는 방안을 제안하고자 합니다. 최종적으로 사용자에게 특정 장소나 시간에 상관없이 전기차를 충전하는 자유를 제공하는 것이 목표입니다.',
    challenge:
      '많은 조건의 균형점을 찾는 것이 가장 큰 도전이었습니다. 이러한 도전은 디자인의 시작점인 형교 형상의 모빌리티 프레임에서 시작되었습니다. ㄷ자 모양이 세로로 세워져 있는, 다리 형상과 같은 프레임 모양을 채택하면서, 배터리 상 하차의 시나리오, 시각적 안정감, 구현 가능성, 크기와 무게 등 많은 고려 사항이 생겼습니다. 또 제품을 개발하기 위해 고려되어야 하는 사용성, 전체 시스템, 사용 장소 등과 맞물려 적절한 균형점을 찾아야 하는 상황이 생겼습니다. 어느 하나를 포기하면 기존의 문제 사항을 해결하기 어려워지기 때문에, 그러한 부분을 조율하는 과정에서 가장 많은 시간을 쏟았습니다.',
    story:
      '사용자가 저희의 제품을 사용하면서 현재의 충전 시스템보다 나은 경험을 해야 하므로 정말 많은 리서치 과정이 있었습니다. 그중 가장 조심스럽고 어려웠던 점은 사용자가 이 충전 시스템을 사용하는 데에 정말로 어려움을 느끼고 있는가, 어떤 어려움인가, 우리 제품을 사용하면서 이것이 과연 더 편리해질까 하는 의문을 해결하는 것이었습니다. 도로를 주행하기 때문에 관련 법규를 찾고, 걷고 있는 사람이 제품을 어떻게 느끼는 지를 연구하고, 차량을 운전하는 사람이 불편하거나 거슬려 하지 않는 지를 고심하는 것 등 사용성과 감정에 대한 부분들이 쉽게 해결되지 않는 문제였습니다. 저에게 졸업설계는 아쉬운 점과 어려운 점도 있었지만, 사용자와 가장 깊게 공감하려고 노력한 프로젝트이기도 합니다. 졸업 후에도 저희의 노력이 종종 생각날 것 같습니다.\n이 방식의 장점은 건물이나 도심에서 분리된 전기 시스템을 사용하기 때문에, 전력 수요의 문제가 해결된다는 점, 노면 상황이 좋지 않은 장소에서도 사용할 수 있다는 점이 있습니다.'
  },
  {
    student_number: 2020151037,
    name: '이시온',
    name_en: 'Si-On Lee',
    sentence: '안되면 되는 걸 하자',
    email: 'ls9382@naver.com',
    plant_name: '쵸파',
    plant_category: '안개초',
    concept:
      '저희의 보조 보행차는 사소한 편리함을 제공합니다. 주 사용층인 노년층 및 신체적으로 불편함을 겪는 사용자들을 고려하여, 기울어진 노면에서도 팔걸이의 각도를 자동으로 평행하게 유지해 편안하게 중심을 잡을 수 있도록 했습니다. 또한, 브레이크의 경우 일반적 브레이크와 반대로 잡으면 움직이고 놓으면 제동이 걸리는 방식으로 제품을 사용하지 않을 시 완전한 브레이크가 걸리도록 하여 안전성을 높였습니다. 이와 같은 설계로 사용자에게 실질적인 도움과 일상 생활을 질을 향상시키기위해 노력했습니다.',
    experience:
      '현재 한국에서는 고령화가 큰 사회적 이슈임에도 불구하고, 그들이 겪는 보행 문제는 여전히 개선되고 있지 않고 있습니다. 저희 작품은 이러한 문제를 해결하고자, 사용자에게 보다 편안하고 안전한 보행 경험을 제공하는 것을 목표로 하고 있습니다. ',
    challenge:
      '기술 구현이 저희에게 가장 큰 도전이었습니다. 저희 과가 공학 기반의 디자인을 지향하지만, 관련 기술을 전문적으로 다루지 않기 때문에, 구상과 실제 구현 사이의 차이를 이번 작품을 통해 많이 느꼈습니다. 이를 극복하기 위해 관련 전문가들의 조언을 지속적으로 구하는 것이 얼마나 중요한지를 깨달았습니다. ',
    story:
      '디자인이 거대한 변화를 가져올 수도 있지만, 작은 변화로도 큰 가치를 가져올 수 있음을 배웠습니다. 매일의 일상적 행위를 온전하게 누리게 함으로써 누군가 에게 좋은 경험과 가치를 제공할 수 있다는 것을 배우게 되는 계기가 되었습니다. '
  },
  {
    student_number: 2021151023,
    name: '박지윤',
    name_en: 'Ji-Yun Park',
    sentence: 'Time for us to shine',
    email: 'oa3b49k@naver.com',
    plant_name: '쑥쑥이',
    plant_category: '바질',
    concept:
      '작품의 주요 컨셉은 반려견과 외출 도중 편의점 출입시 여러 불편함이 있기 때문에 반려견을 잠시 맡길 수 있는 보관함을 제작하게 되었습니다.',
    experience:
      '반려가구 시대에 있어 흔히 겪을 수 있는 문제점에서 개선할 수 있는 점을 찾게되었습니다. 반려견과 함께하는 일상 속에서 함께 외출을 했을 때 출입제한 시설 중 편의점을 타겟으로 하여 보관함을 배치하여 불편함을 해소하고자 했습니다. ',
    challenge:
      '제품을 만들면서 가장 큰 도전은 상황과 컨셉에 맞는 디자인 형태를 찾아내는 것에 어려움이 있었습니다. 이를 극복하기 위해 지도교수님과 멘토님의 의견을 수렴하여 수정하고 개선될 수 있도록 노력하였습니다. 이러한 노력 덕분에 저희는 정한 컨셉에 맞는 적절한 디자인을 찾을 수 있었습니다.',
    story:
      '정해져 있는 제품에 디자인이 여러 형태로 바뀌면서 다르게 보일 수 있다는 것을 알게 되었고, 한 제품을 만들 때 다양하고 여러 방면에서 꼼꼼히 생각해야 된다는 것을 알게됨으로써 디자인을 생각하는 부분에 있어 성장하게 된 것 같습니다.'
  },
  {
    student_number: 2021151052,
    name: '이수빈',
    name_en: 'Soo-Bin Lee',
    sentence: '독창적인 디자인으로 편리함을 만들자',
    email: 'beenigood@naver.com',
    instagram: 'bslooe',
    plant_name: '수빈 아닌 얼룩강낭빈',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 아이디어나 컨셉은 계단을 오르며 청소하는 로봇 청소기입니다. 이런 컨셉을 달성하기 위해 저희는 로커-보기 구조를 활용하여 계단을 오르도록 하였습니다.',
    experience:
      '저희가 제작한 계단을 오르는 청소기를 통해 전달하고자 하는 메시지는 기술이 일상생활의 불편함을 어떻게 해결할 수 있는지를 보여주는 것입니다. 사용자에게는 이러한 청소기를 통해 계단 청소의 번거로움을 줄이고, 더 많은 시간을 절약하여 여가나 다른 중요한 일에 집중할 수 있는 경험을 제공하고자 합니다. 또한, 혁신적인 로커 보기 구조의 활용을 통해 복잡한 문제를 해결하는 창의적 접근 방식을 강조하고자 합니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 예상치 못한 기술적인 문제였습니다. 기술적 구현 능력이 부족하다 보니 기술적 문제를 구현하는 데 한계를 느꼈기에 기계학과의 지인에게 도움을 요청하여 기술적인 부분을 구현할 수 있도록 진행하였습니다.',
    story:
      '위 프로젝트를 통해 계단을 오르는 청소기의 로커 보기 구조를 활용한 디자인을 개발함으로써, 복잡한 환경에서도 청소가 가능한 새로운 가능성을 열었습니다. 이 경험을 통해 사용자 중심의 디자인을 더욱 깊이 이해하고, 기술과 디자인의 융합이 어떻게 실생활 문제를 해결할 수 있는지 배웠습니다. 개인적으로는 협업과 문제 해결 능력이 크게 향상되었으며, 창의적인 아이디어를 현실로 구현하는 과정에서 큰 성취감을 느꼈습니다.'
  },
  {
    student_number: 2021151028,
    name: '신동찬',
    name_en: 'Dong-Chan Shin',
    sentence: '빠르게 가지 말고 바르게 가자. ',
    email: 'goodday616@gmail.com',
    instagram: 'chann6__',
    plant_name: '동동이',
    plant_category: '바질',
    concept:
      '저희 작품의 주요 컨셉은 개인형 맞춤 영양소를 웨어러블 기기를 통해 제공해주는 것입니다. 코로나 펜데믹 이후 개인 건강관리 목적으로 종합영양제에 대한 수요가 늘어나는 추세입니다. 그런데 개인이 가지고 있는 기저 질환에 부작용을 일으킬 수 있는 영양소를 간과한 채로 섭취하여 부작용을 겪는 사례 또한 늘어나고 있습니다. 저희는 이러한 문제를 해결하기 위해 개인형 맞춤 영양소를 제공해 줄 수 있는 웨어러블 기기 및 패치 생성기기를 고안하였습니다. ',
    experience:
      '작품을 통해 전달하고자 하는 메세지는 차기 기술이 우리 일상 속으로 적용되었을 때의 모습을 제안하는 것입니다. 저희는 발전 잠재력이 있는 초음파 패치 기술을 활용해 사용자가 편리하게 개인 맞춤영양소를 섭취하는 모습을 강조하고자 했습니다. ',
    challenge:
      '작품을 만들면서 가장 큰 어려움은 기존에 없던 새로운 제품을 만드는 것이었습니다. 기존의 어떤 문제를 가진 제품을 개선하는 것이 아닌, 초음파 패치라는 핵심 기술이 적용되는 제품을 제작하다보니 제품의 디자인 및 내부 구조를 새로 정립하고 그에 따라 설계하는 것이 큰 어려움이었습니다. 이를 극복하기 위해 저희가 설계한 구조와 유사한 제품들의 내부 구조를 찾아보고, 적용 및 발전시켰습니다. 디자인은 설계한 구조에 맞추어 다양하게 아이디에이션하였습니다. 이러한 노력 덕분에 저희는 기존에 없던 새로운 제품이더라도 구조적 논리성을 갖춘 디자인 결과물을 만들어 낼 수 있었습니다. ',
    story:
      '맞춤 영양소를 투여 할 수 있는 웨어러블및 기기를 통해, 일상생활뿐만 아니라 구강투여가 제한되는 환자 및 재난 상황에서도 사용할 수 있는 다양한 가능성을 열어주었다고 생각합니다. 저는 이번 졸업 작품을 통해 다방면의 리서치를 통해 논리적인 디자인 아이디어를 얻는 과정을 배웠다고 생각하였고, 이는 제 향후 디자이너로서의 삶에 있어서 중요한 전환점이 될 것 같습니다. '
  },
  {
    student_number: 2019151010,
    name: '김익현',
    name_en: 'Ik-Hyeon Kim',
    sentence: '못먹어도 고',
    email: 'dlrgus0313@naver.com',
    instagram: 'dlrgus0313',
    plant_name: '세바스챤',
    plant_category: '안개초',
    concept:
      '작품의 주 컨셉은 소방관들의 안전하고 신속한 꿀벌 포획입니다. 현재 꿀벌의 개체 수 감소, 그리고 꿀벌의 지구에서의 중요성은 날로 증가하고 있습니다. 벌집 관련 신고는 119를 통해 하며, 이 벌집 관련 민원은 소방관이 처리하는 것이 현재 시스템입니다. 이때 저희는 보다 안전한 꿀벌 이송을 위해, 소방관들의 쉽고 빠른 벌 포집과 이송이 간편하도록 노력하였습니다.',
    experience:
      '저희는 이 작품을 통해 꿀벌의 소중함을 전달하도록 하고 싶었습니다. 추가로 소방관분들의 신속하고 안전한 꿀벌 포집을 통해 기존 벌집 관련 민원 처리 과정보다 훨씬 효율적이고 안전한 경험을 주고 싶었습니다. 그리고 이러한 경험이 자연과 현대인이 공존할 수 있다는 것을 강조하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 어려움은 기술의 작동 여부를 확인하는 부분이 어려웠습니다. 처음에는 머릿속에서만 막연히 되겠다고 판단한 기술들이, 자료를 찾고, 설계를 해보면서 점점 가능성에 대한 자신이 없어졌습니다. 결국 마지막에는 기존의 이론이나 설계를 모두 생각하지 않고 무작정 테스트용 목업을 제작했는데, 놀랍게도 실제로 작동했습니다. 지금까지의 고민과 걱정이 무색하게도 멋지게 작동하는 목업을 보면서 저희는 깨달았습니다. 역시 일단 해보는 것이 중요하구나. 실수를 두려워할지언정 실패를 두려워해서는 안 되는구나. 디자인을 이론부터 차근차근 쌓아 올리는 저희의 기존 디자인 방법에서 벗어난 이러한 방법은 저희에게 큰 도전이었습니다. 막상 문제에 부딪히고 나서 간단하게 해결된 문제를 보면서 다시 한번 행동의 중요성에 대한 교훈을 얻게 되었습니다.',
    story:
      '저는 이번 작품을 설계하면서 사용자의 사용성도 중요했지만, 가장 중요하게 생각했던 부분은 꿀벌의 안전이었습니다. 자칫 잘못하면 사람에게만 이기적으로 다가올 수 있는 제품이 꿀벌을 위한, 모두를 위한 작품으로 완성됨으로써 저희 이지비지의 가치가 완성되었다고 생각합니다. 이를 통해 저는 제품을 사용하는 1차 사용자 뿐 아니라 제품에 의해 영향을 받는 3자의 입장 또한 생각하게 되는 디자이너로 성장하게 된 것 같습니다.'
  },
  {
    student_number: 2019151020,
    name: '백승선',
    name_en: 'Seung-Seon Baek',
    sentence: '의심하는 디자이너 백승선입니다.',
    email: 'bss1702a@gmail.com',
    instagram: 'baek.design',
    photopolio_link: 'https://baekdesign.myportfolio.com/',
    plant_name: '달빛바다속푸른하늘위숲속작은나무',
    plant_category: '얼룩강낭콩',
    concept:
      '저희 제품의 주요 컨셉은 배터리 딜리버리 로봇이 배터리를 견인하여 자유롭게 주행하는 것입니다. 늘어나는 전기차 충전 수요만큼의 충전소를 만들게 된다면, 해당 전력을 부담할 수 있는 발전소를 지어야 하며 도시 전기 그리드에도 부담이 됩니다. 이를 해결하기 위해 배터리를 활용하여 전기차의 충전을 지원하고자 했습니다. 전기차 충전 설비가 적은 대형 주차장에서 전기차의 충전이 필요한 경우, 로봇이 배터리를 운반하여 사용자가 지정한 장소에 두고, 사용자가 운반된 배터리의 충전기를 전기차에 꽂아 차량을 충전하는 방식입니다. ',
    experience:
      '전기차 충전과 관련된 제약 조건들을 없애고, 도심 전력망에 부담을 줄여야 한다는 환경 문제를 해결하는 등 기존의 고전적인 충전 방식에서 벗어나 더 유연하고 효율적으로 대처할 수 있는 방안을 제안하고자 합니다. 최종적으로 사용자에게 특정 장소나 시간에 상관없이 전기차를 충전하는 자유를 제공하는 것이 목표입니다.',
    challenge:
      '많은 조건의 균형점을 찾는 것이 가장 큰 도전이었습니다. 이러한 도전은 디자인의 시작점인 형교 형상의 모빌리티 프레임에서 시작되었습니다. ㄷ자 모양이 세로로 세워져 있는, 다리 형상과 같은 프레임 모양을 채택하면서, 배터리 상 하차의 시나리오, 시각적 안정감, 구현 가능성, 크기와 무게 등 많은 고려 사항이 생겼습니다. 또 제품을 개발하기 위해 고려되어야 하는 사용성, 전체 시스템, 사용 장소 등과 맞물려 적절한 균형점을 찾아야 하는 상황이 생겼습니다. 어느 하나를 포기하면 기존의 문제 사항을 해결하기 어려워지기 때문에, 그러한 부분을 조율하는 과정에서 가장 많은 시간을 쏟았습니다.',
    story:
      '고려 사항이 많은 만큼, 정말 많은 시나리오를 필요로 하게 된 작품이었습니다. 제품이 사용되는 시나리오를 커다란 기반으로 해서 제품을 디자인했지만, 이를 워킹을 통해 실체화하는 과정에서 이 모빌리티가 어떻게 움직여야 하는지, 배터리를 탈부착하는 방식과 그 스탭이 어떻게 되는지, 사용자는 충전을 어떻게 하는지 등 많고 작은 시나리오들을 고려하고 구체화하는 단계를 통해, 좀 더 실제적인 것을 만들어 나갈 수 있는 디자이너가 된 것 같습니다.\n이러한 것을 팀원과 함께 직접 만들고, 문제를 해쳐 나가는 과정 자체는 힘들었지만, 이렇게 인터뷰를 통해 돌아보니, 하나의 프로젝트에서 정말 많은 경험 쌓을 수 있었습니다.'
  },
  {
    student_number: 2021151024,
    name: '박채연',
    name_en: 'Chae-Yeon Park',
    sentence: '하고 싶은 게 많은 디자이너',
    email: 'itsmecyp@gmail.com',
    instagram: 'works_cy',
    plant_name: '총총이',
    plant_category: '안개초',
    concept:
      '저희 작품의 가장 중요한 컨셉은 안전입니다. 이안류는 역류하는 파도로, 이안류에 휩쓸리면 사람들이 해안가로부터 점점 멀어지게 됩니다. 그런 상황에 대비하고 생명이 달린 문제이기 때문에 안전이 무엇보다 중요합니다. ',
    experience:
      '저희는 더욱 안정적인 구조 대기와 빠른 구조가 가능하다는 것을 전달하고 싶습니다. 물에 빠지게 되면 시야가 좁아지고, 발이 땅에 닿지 않으면서 몸에 힘이 빠른 속도로 빠지게 됩니다. 이런 익수자들의 빠른 구조를 도울 수 있는 부표라는 것을 전하고 싶습니다.',
    challenge:
      '저희에게 있어 가장 큰 도전은 기술의 현실성을 부여하는 것이었습니다. 사실 파력 발전 자체에 대한 원리와 관련한 자료가 많이 없었기 때문에 자체적으로 발전할 수 있는 원리만을 가지고 프로토타입을 제작하여 저희가 생각한 기술이 실제로 가능한 건지 실험하는 과정이 저희에게 가장 큰 도전이었던 것 같습니다. ',
    story:
      '저는 안전이라는 주제를 선택한 것이 큰 의미였습니다. 안전이라는 게 고려할 사항들이 많고, 산업적인 면에서도 설득력이 있어야 하는데 그 중점을 찾는 것이 어려웠습니다. 디자인도 근거가 바탕이 되어야 해 전에 비해 더욱 시간과 노력이 많이 필요했는데, 이런 과정에서 시야가 넓어지고 꾸준하게 해내는 것의 중요성과 제 뭔가를 해냈을 때의 희열을 배우면서 끝까지 해내는 것이 무엇인지 배웠습니다. 이런 모든 게 나중에 저에게 도움이 될 거라 생각합니다.'
  },
  {
    student_number: 2019151008,
    name: '김승규',
    name_en: 'Seung-Gyu Kim',
    sentence: '달걀은 익으면 끝이다',
    email: 'ksgdragon@naver.com',
    plant_name: '수육',
    plant_category: '안개초',
    concept:
      '작품의 주 컨셉은 소방관들의 안전하고 신속한 꿀벌 포획입니다. 현재 꿀벌의 개체 수 감소, 그리고 꿀벌의 지구에서의 중요성은 날로 증가하고 있습니다. 벌집 관련 신고는 119를 통해 하며, 이 벌집 관련 민원은 소방관이 처리하는 것이 현재 시스템입니다. 이때 저희는 보다 안전한 꿀벌 이송을 위해, 소방관들의 쉽고 빠른 벌 포집과 이송이 간편하도록 노력하였습니다.',
    experience:
      '저희는 이 작품을 통해 꿀벌의 소중함을 전달하도록 하고 싶었습니다. 추가로 소방관분들의 신속하고 안전한 꿀벌 포집을 통해 기존 벌집 관련 민원 처리 과정보다 훨씬 효율적이고 안전한 경험을 주고 싶었습니다. 그리고 이러한 경험이 자연과 현대인이 공존할 수 있다는 것을 강조하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 어려움은 기술의 작동 여부를 확인하는 부분이 어려웠습니다. 처음에는 머릿속에서만 막연히 되겠다고 판단한 기술들이, 자료를 찾고, 설계를 해보면서 점점 가능성에 대한 자신이 없어졌습니다. 결국 마지막에는 기존의 이론이나 설계를 모두 생각하지 않고 무작정 테스트용 목업을 제작했는데, 놀랍게도 실제로 작동했습니다. 지금까지의 고민과 걱정이 무색하게도 멋지게 작동하는 목업을 보면서 저희는 깨달았습니다. 역시 일단 해보는 것이 중요하구나. 실수를 두려워할지언정 실패를 두려워해서는 안 되는구나. 디자인을 이론부터 차근차근 쌓아 올리는 저희의 기존 디자인 방법에서 벗어난 이러한 방법은 저희에게 큰 도전이었습니다. 막상 문제에 부딪히고 나서 간단하게 해결된 문제를 보면서 다시 한번 행동의 중요성에 대한 교훈을 얻게 되었습니다.',
    story:
      '저희의 제품, 이지비지를 통해 흡입기의 휴대성을 높일 수 있는 가능성을 열었습니다. 가장 끝부분에 힘의 작용점이 있는 기존 흡입기에 비해, 저희는 중간 부분에 힘의 작용점이 있기에 딱딱하고 고정적인 기존 틀에서 벗어날 수 있었습니다. 그리고 저는 이 제품의 디자인 과정을 통해 나라에 도움을 주시는 소방관 분들이나 경찰관 분들같이 효율적인 면을 특히나 중시하는 분야에서도 디자인이 필요할 수 있겠다는 생각이 들었습니다. 그렇기에 저의 경험과 공감성이 확장된 것 같습니다.'
  },
  {
    student_number: 2021151043,
    name: '최성우',
    name_en: 'Sung-Woo Choi',
    sentence: 'Slow down and enjoy',
    email: 'swoo5239@naver.com',
    instagram: 'seongu260',
    plant_name: '바질 아블로',
    plant_category: '바질',
    concept:
      '저희 작품의 주요 컨셉은 개인형 맞춤 영양소를 웨어러블 기기를 통해 제공해주는 것입니다. 코로나 펜데믹 이후 개인 건강관리 목적으로 종합영양제에 대한 수요가 늘어나는 추세입니다. 그런데 개인이 가지고 있는 기저 질환에 부작용을 일으킬 수 있는 영양소를 간과한 채로 섭취하여 부작용을 겪는 사례 또한 늘어나고 있습니다. 저희는 이러한 문제를 해결하기 위해 개인형 맞춤 영양소를 제공해 줄 수 있는 웨어러블 기기 및 패치 생성기기를 고안하였습니다. ',
    experience:
      '작품을 통해 전달하고자 하는 메세지는 차기 기술이 우리 일상 속으로 적용되었을 때의 모습을 제안하는 것입니다. 저희는 발전 잠재력이 있는 초음파 패치 기술을 활용해 사용자가 편리하게 개인 맞춤영양소를 섭취하는 모습을 강조하고자 했습니다. ',
    challenge:
      '작품을 만들면서 가장 큰 어려움은 기존에 없던 새로운 제품을 만드는 것이었습니다. 기존의 어떤 문제를 가진 제품을 개선하는 것이 아닌, 초음파 패치라는 핵심 기술이 적용되는 제품을 제작하다보니 제품의 디자인 및 내부 구조를 새로 정립하고 그에 따라 설계하는 것이 큰 어려움이었습니다. 이를 극복하기 위해 저희가 설계한 구조와 유사한 제품들의 내부 구조를 찾아보고, 적용 및 발전시켰습니다. 디자인은 설계한 구조에 맞추어 다양하게 아이디에이션하였습니다. 이러한 노력 덕분에 저희는 기존에 없던 새로운 제품이더라도 구조적 논리성을 갖춘 디자인 결과물을 만들어 낼 수 있었습니다. ',
    story:
      '저희가 생각한 패치기술 즉 마이크로 니들 기술은 현재도 발전중이고 멀지 않은 미래기술 입니다. 저희는 이러한 주제를 통하여 사용자에게 현재보다 나은 삶과 보다 나은 경험을 할 수 있는 제품을 고안하였습니다. 이번 졸설을 진행하면서 기존에 없던 새로운 제품을 디자인하는 과정을 재밌게 하다보니 이러한 과정에서 많이 성장한 것 같습니다.'
  },
  {
    student_number: 2019151032,
    name: '이한',
    name_en: 'Han Lee',
    sentence: '안녕하세요. 저는 [더보기···]',
    email: 'dlgks218@gmail.com',
    plant_name: '꽁꽁이',
    plant_category: '안개초',
    concept:
      '작품의 주요 아이디어나 컨셉은 [Recycle(순환), Reverse(반전), Lever(지렛대)]입니다. Recycle은 캡슐을 넣고 분리배출하는 과정까지 제품 내에서 이뤄지는 주기적인 행위를 의미하며, Reverse는 캡슐을 뒤집어서 분리하기에 반전으로 표현하였고, Lever는 캡슐분리에 사용된 지렛대를 의미합니다. [Recycle, Reverse, Lever] 세 가지 컨셉을 중점으로 ‘간편한 환경운동의 목적’을 달성하기 위해 노력했습니다.',
    experience:
      '작품을 통해 전달하고자 하는 메시지는 [무의식으로 하는 환경운동]입니다. 사용자가 자신도 모르게 분리배출을 하고 있다는 편리함을 강조하고, 환경 운동에 동참하고 있다는 동기부여를 제공하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 [언어를 시각적으로 표현하는 것]이었습니다. 초반에는 기술적인 부분에 집중하다 보니, 컨셉 선정과 디자인에 대한 연구가 다소 미흡했습니다. 이를 극복하기 위해 [레퍼런스 및 면담]을 자주 활용하였습니다. 커피머신과 관련이 없는 제품도 참고하며 디자인 아이디어를 얻기 위해 노력하였고, 지도교수님과 멘토님뿐만 아니라, 다른 교수님들도 찾아뵈며 다양한 시야에서 조언을 구하기 위해 힘썼습니다. 많은 분이 도와주신 덕분에 [컨셉을 제품에 잘 녹여내어 디자인]할 수 있었습니다.',
    story:
      '이번 프로젝트를 하기 전부터 [외로운 싸움]이라고 자주 되새겼습니다. 팀원 없이 해내야 하는 외로움과 아이디어의 한계에서 느껴지는 외로움에, 종종 무너질뻔했는데 지금은 혼자서도 해냈다는 뿌듯함이 듭니다. 이 프로젝트를 통해 ‘용기와 자신감’을 얻었습니다.'
  },
  {
    student_number: 2021151002,
    name: '강호정',
    name_en: 'Ho-Jeong Kang',
    sentence: '순간순간 행복하기',
    email: 'rkdghwjd0116@naver.com',
    instagram: '_gnoejoh',
    photopolio_link: 'https://www.behance.net/aa0b8d0b',
    plant_name: '강냉이',
    plant_category: '얼룩강낭콩',
    concept:
      "작품의 주요 아이디어는 바다 사막화로 인해 훼손된 생태계에서 자연적인 회복이 어려운 해초를 복원하기 위한 모빌리티를 제작하는 것입니다. 현재 바다 사막화의 확산 추세에 비해 기존 해결책이 미미하여 문제를 더욱 심화시키고 있습니다. 저희는 다이버의 노동력을 줄이고 잘피의 생존율을 높여 바다숲을 조성하기 위해 'SEADER'를 디자인하게 되었습니다.",
    experience:
      '작품을 통해 전달하고자 하는 메시지는 바다 사막화의 심각성과 탄소 포집의 실천을 돕는 것입니다. 스티븐슨에 따르면, 지금처럼 수작업으로 진행할 경우, 발트해의 해조 복구를 위해 다이버 50만 명이 하루에 12시간씩 1년 동안 작업해야 합니다. 저희의 제품으로 바다숲 복원의 효율을 높이고, 더 나아가 캠페인을 기획하고자 합니다. 사람들이 직접적인 참여를 유도해 바다 생태계에 많은 관심을 갖고자 하는 것이 목표입니다.',
    challenge:
      '저희는 타과랑 함께 작품을 제작하는 것이 큰 도전이었어요. 일단 타과랑 같이 하나의 프로젝트를 진행한 경험이 많이 없었고, 친하지도 않은 사람들이라 걱정이 많았죠. 이를 극복했다기보다는 각자 자신 맡은 역할에 최선을 다해 큰 어려움은 없었습니다. 대신 디자인에서 끝나는 게 아니라는 걸 느꼈어요. 저희가 제품을 디자인할 때 구현이 가능한 구조를 설계하려고 하지만, 실제 구현에 맞지 않을 수 있잖아요. 그런 서로가 부족한 점을 채워나갈 수 있었죠. 콘셉트와 디자인만 보여주는 제품이 아니라 실제 구현 가능성을 보여줄 수 있어서 저희 제품에 더 자신할 수 있게 된 것 같아요.',
    story:
      '아직 저에게는 4년이라는 시간에 성장했다는 단어를 넣긴 어려운 것 같아요. 스스로 성장했다고 의식하면 다음으로 더 성장하는 걸 어려워하거든요! 그렇지만 대학 생활에서의 디자인의 모든 과정을 겪어보며 저에 현재 상황에 빗대어 느끼는 감정은, 이 정도면 디자인을 하고 싶은 게 맞다는 마음이 가득한 건 확실한 것을 알 수 있는 것 같아요.'
  },
  {
    student_number: 2019151040,
    name: '최혁수',
    name_en: 'Hyeok-Su Choi',
    sentence: '비열한 성공보다는 진실된 실패를',
    email: 'grandchoi7194@gmail.com',
    instagram: 'from_five_ceramics',
    plant_name: '까망',
    plant_category: '안개초',
    concept:
      '저희 작품의 가장 중요한 컨셉은 안전입니다. 이안류는 역류하는 파도로, 이안류에 휩쓸리면 사람들이 해안가로부터 점점 멀어지게 됩니다. 그런 상황에 대비하고 생명이 달린 문제이기 때문에 안전이 무엇보다 중요합니다. ',
    experience:
      '저희는 더욱 안정적인 구조 대기와 빠른 구조가 가능하다는 것을 전달하고 싶습니다. 물에 빠지게 되면 시야가 좁아지고, 발이 땅에 닿지 않으면서 몸에 힘이 빠른 속도로 빠지게 됩니다. 이런 익수자들의 빠른 구조를 도울 수 있는 부표라는 것을 전하고 싶습니다.',
    challenge:
      '저희에게 있어 가장 큰 도전은 기술의 현실성을 부여하는 것이었습니다. 사실 파력 발전 자체에 대한 원리와 관련한 자료가 많이 없었기 때문에 자체적으로 발전할 수 있는 원리만을 가지고 프로토타입을 제작하여 저희가 생각한 기술이 실제로 가능한 건지 실험하는 과정이 저희에게 가장 큰 도전이었던 것 같습니다. ',
    story:
      "저는 사실 디자인과 공학이 어떻게 함께 있을 수 있는지 이해를 하지 못했던 학생이었습니다. 제품의 디자인이 그저 제품의 외관을 예쁘게 하거나, 디자인으로써 어떤 공학의 효율을 끌어내는 것이 가능한 건지에 대한 의문을 항상 가지고 있었습니다. 하지만 저희의 제품에서 물 입자 원궤도 운동을 통해 파력발전이 가능한 모델을 보여줌으로써 이것이 가능하다는 것을 알게 되었고 앞으로의 디자인에 이 경험이 영향을 미쳐 '이유 있는 디자인'을 할 수 있게 한 발짝 성장할 수 있었습니다."
  },
  {
    student_number: 2019151023,
    name: '신민우',
    name_en: 'Min-Woo Shin',
    sentence: '내 길을 달리고 이루고 날아가 함께',
    email: 'tlsalsdn1021@gmail.com',
    instagram: 'ming_gu1021',
    plant_name: '메르세데스 빈츠',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 아이디어나 컨셉은 개인 소유형 퍼스널 모빌리티와 대중교통의 연계 입니다. 최근 사회에서 문제가 되고 있는 공유방식의 PM을 소유방식으로 전환함으로써 문제를 해결하고, 소유형으로 전환하였을 때 대중교통과 연계가 쉽게 하여 소유형 PM의 사용성과 편의성을 높이는 것을 중심으로 PM을 들어 올림과 동시에 접히도록하는 접이 방식을 구현하기 위해 노력하였습니다.',
    experience:
      '작품을 통해 전달하고자 하는 메시지나 사용자에게 전달하고자 하는 경험은 접이식 기능을 통해 PM과 대중교통의 연계성을 높여 사용자의 이동 편의성을 높이는 것입니다. 이 경험을 위해 PM의 접이식 방식이 보다 간편하고 쉽게 접히도록 하는 구조를 제안하고자 했습니다.',
    challenge:
      '기존에 없었던 접이 방식을 설계 해야했기 때문에 그 과정이 가장 어려웠습니다. 이를 극복하기 위해 이루고자 하는 목표를 계속 생각해보면서 직접 새로운 구조를 설계하고 모델링을 진행하여 시현해보았습니다. 이러한 노력 덕분에 저희는 최종 접이 방식을 도출했고 대중교통과의 연계성을 높이고자 했던 목표에 도달할 수 있게 되었습니다.',
    story:
      '이번 모빌리티 개발이 올바른 방향으로 이루어진다면, 지금까지 공유의 방향으로 나아가던 퍼스널 모빌리티 시장이 새로운 전환점을 맞이할 것이라 생각합니다. 공유 모빌리티의 단점이 지속적으로 부각되는 이 시점에서 저희의 제품이 도움이 될거라 생각합니다. 이 모빌리티가 지속적으로 개발된다면 공유 PM시장은 앞으로 개인 소유형 시장으로 나아갈 수 있습니다. 저는 저희의 모빌리티가 미래 시장의 새로운 바람을 불어 넣어 줄거라 기대합니다. 이번 프로젝트를 통해 처음으로 모빌리티 기술을 배우고, 팀원들과 본격적으로 큰 프로젝트를 진행하면서 제 가능성을 새롭게 발견했습니다. 이는 저에게 새로운 방향으로 나아갈 수 있는 길을 열어주었고, 매우 뜻깊은 시간이라 생각했습니다.'
  },
  {
    student_number: 2021151018,
    name: '김혜민',
    name_en: 'Hye-Min Kim ',
    sentence: '안녕하세요, 디자이너 김혜민입니다.',
    email: 'yo_dream_js@naver.com',
    instagram: 'the__autum.n',
    photopolio_link: 'wittydesign.creatorlink.net',
    plant_name: '얼콩이',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 아이디어나 컨셉은 계단을 오르며 청소하는 로봇 청소기입니다. 이런 컨셉을 달성하기 위해 저희는 로커-보기 구조를 활용하여 계단을 오르도록 하였습니다.',
    experience:
      '저희가 제작한 계단을 오르는 청소기를 통해 전달하고자 하는 메시지는 기술이 일상생활의 불편함을 어떻게 해결할 수 있는지를 보여주는 것입니다. 사용자에게는 이러한 청소기를 통해 계단 청소의 번거로움을 줄이고, 더 많은 시간을 절약하여 여가나 다른 중요한 일에 집중할 수 있는 경험을 제공하고자 합니다. 또한, 혁신적인 로커 보기 구조의 활용을 통해 복잡한 문제를 해결하는 창의적 접근 방식을 강조하고자 합니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 예상치 못한 기술적인 문제였습니다. 기술적 구현 능력이 부족하다 보니 기술적 문제를 구현하는 데 한계를 느꼈기에 기계학과의 지인에게 도움을 요청하여 기술적인 부분을 구현할 수 있도록 진행하였습니다.',
    story:
      '미국 항공 우주국, 나사에서 실제적으로 쓰이는 탐사 로봇인 만큼 지형이 평평하지 못한 곳에서 안정적으로 주행이 가능한 로커 보기 구조인데, 이 구조를 활용하여 계단을 올라갈 수 있는 로봇을 제작하는 것이 또 다른 가능성을 만들게 하였습니다. 기술 실현 가능성을 위해 끊임없는 노력과 탐구 덕에 이뤄낼 수 있다고 생각합니다. 끊임없는 노력을 하며 나에 대한 성장을 할 수 있었고, 잊지 못할 졸업 작품 제작 과정이라 느껴졌습니다. 과정을 수행하며 많은 조언 주신 지도 교수님, 기술 구현의 의견을 준 기계 친구에게도 감사의 말 전합니다.'
  },
  {
    student_number: 2021151050,
    name: '김호빈',
    name_en: 'Ho-Been Kim',
    sentence: '심플함 속에 담긴 디자인',
    email: 'ehb715@naver.com',
    plant_name: '달짝이',
    plant_category: '바질',
    concept:
      '요즘 사라졌던 umpc가 게이밍 컨셉으로 다시 부활하였습니다. 하지만 핸드핼드기기인 제품이 아이러니하게도 무거운 무게와 발열과 같은 치명적인 단점을 가지고 있고 성능은 높지않지만 고가의 노트북과 비슷한 가격대를 형성하고 있습니다.많은 사람들이 앞으로 이 게이밍시장이 커질것이라고 예상하는 만큼 이런 단점들을 무선디스플레이 기술을 활용하여 해결하는 새로운 게이밍폼팩터를 만들자 라는 의도에서 시작되었습니다 ',
    experience:
      '작품을 통하여 사용자에게 가장 크게 전달하고자 하는 것은 쾌적한 사용감 이였습니다. 핸들핼드 기기에서 발생하는 무게와 발열을 최소화 하면서 알맞은 그립감을 사용자에게 제공함으로써 쾌적한 사용성을 느끼게 하고 싶었습니다.',
    challenge:
      '이 작품을 진행하기 전에 하려고했던 다른 작품이 있었다. 교수님들에게 조금은 생소한 게이밍이라는것을 졸업작품에 녹여내기가 사실 어려울것이라고 예상은 하였기 때문이고 기술적으로 구현할수 있을지에 대해 우려가 많았었다 그럼에도 이 작품을 진행을 한 이유는 팀원과 내가 이런 제품쪽에 평소 관심이 많았기도 했고 앞으로 이런일을 많이 진행해 보고 싶었기에 포트폴리오에 한번쯤 이런 작품을 올려보는게 충분히 가치가 있을거라고 생각했다. 진행하고 있는 아이디어에 대해서 지속적으로 의문을 품으며 생각하였고 시장성과 사용성에 대해 정말 많이 조사하였다 발표때는  직접 umpc를 가져와서 조사한 시장성과 현재 가지고 있는 단점들에 대해  설명하면서 진행하고 있는 아이디어를 이해하고 설득하는데 노력을 정말 많이 하였다. 가끔 답답한 기분이 들고 작품을 진행하는데에 있어서 회의감이 들기도 하였지만 이런것도 하나의 경험이라고 생각하면서 계속 진행하였다. 그리고 어느정도 아이디어를 이해시키고 설득시키는데 성공하였다. 이런 노력들은 내가 가진 아이디어를 생소하게 생각하는 사람들에게 어떻게 이해시키고 설득시키는지 그리고 어떻게 작품에 녹일수 있는지에 대한 좋은 경험이 되었고 앞으로 진행될 여러 작품에 좋은 자신감을 심어주었다.',
    story:
      '무선 디스플레이와 컨트롤러 기술을 융합하여 새로운 폼팩터의 기기가 지금까지 사용하던 핸드헬드 게임기를 사용하던 경험과는 다른 새로운 경험을 제공해 줄 수 있습니다. 이를 통해 문제 해결능력, 사용자 중심 사고를 키웠고, 계속해서 발전시키고 있습니다.'
  },
  {
    student_number: 2019151038,
    name: '천세강',
    name_en: 'Se-Kang Chun',
    sentence: '항상 즉시 기쁘게 다시 시작하자',
    email: 'chunsekang@gmail.com',
    plant_name: '천개초',
    plant_category: '안개초',
    concept:
      '주요 컨셉은 재활의지를 돕기 위해 산책과 재활을 합쳤다는 것입니다. 관절 구축이란 쉽게 말해서 관절을 사용하지 않아 굳는 질병인데, 스스로 거동이 불가하거나, 침상 생활을 오래 하는 경우 관절 구축이 발생합니다. 때문에 주기적으로 관절을 움직여야 하는데,  스스로 움직일 수 없는 경우엔 요양사나 보호자가 수동운동을 해줘야 합니다. 이때 자의로 움직일 수 없는 신체를 누군가 운동시켜준다는 게 재활 환자 입장에서 썩 유쾌하지 않겠다는 생각을 했습니다. 하고 싶은 것을 할 때 하기 싫은 것까지 해결된다면 좋지 않을까? 하는 아이디어로 시작되었습니다.',
    experience:
      '재활은 의지가 매우 중요합니다. 그래서 조금이라도 즐겁게 재활을 했으면 하는 마음으로 디자인하였습니다. 사람들은 자전거를 보면 당연히 페달을 밟아 바퀴를 굴리는 생각을 합니다. 재활환자가 제품을 통해 이와 비슷한 행위를 하면서 내가 스스로 힘주어 앞으로 나아가는 기분을 느꼈으면 합니다.',
    challenge:
      '조향에 관련하여 고민을 많이 했습니다. 바퀴가 하나인 디자인으로 전개를 해오다가 조향을 위해서 바퀴 하나를 더 추가했고 그 과정에서 디자인 전체가 바뀌어서 처음부터 다시 시작하게 된 시점이 있는데 그때 밤을 자주 새면서 체력적으로 굉장히 힘들었습니다. 커피와 음악이 큰 도움이 되었습니다.',
    story:
      '저희 제품을 통해 움직임이 불편한 노인분들이 재활운동을 더 즐겁게 임할 수 있게 되며 윤택한 노년생활을 즐길수 있으셨으면 좋겠고 앞으로도 더욱 사회에 도움이 될 수 있는 디자인을 해야겠다고 생각했습니다.'
  },
  {
    student_number: 2020151032,
    name: '윤채원',
    name_en: 'Chae-Won Yun',
    sentence: '안녕하세요 디자인공학과 윤채원입니다.',
    email: 'dbscodnjs01@naver.com',
    plant_name: '바질이',
    plant_category: '바질',
    concept:
      '작품의 주요 컨셉은 반려견과 외출 도중 편의점 출입시 여러 불편함이 있기 때문에 반려견을 잠시 맡길 수 있는 보관함을 제작하게 되었습니다.',
    experience:
      '반려가구 시대에 있어 흔히 겪을 수 있는 문제점에서 개선할 수 있는 점을 찾게되었습니다. 반려견과 함께하는 일상 속에서 함께 외출을 했을 때 출입제한 시설 중 편의점을 타겟으로 하여 보관함을 배치하여 불편함을 해소하고자 했습니다. ',
    challenge:
      '제품을 만들면서 가장 큰 도전은 상황과 컨셉에 맞는 디자인 형태를 찾아내는 것에 어려움이 있었습니다. 이를 극복하기 위해 지도교수님과 멘토님의 의견을 수렴하여 수정하고 개선될 수 있도록 노력하였습니다. 이러한 노력 덕분에 저희는 정한 컨셉에 맞는 적절한 디자인을 찾을 수 있었습니다.',
    story:
      '현재 일상화 돼있지 않은 제품이기 때문에 모든 상황과 컨셉을 고려해야 된다는 점에서 어려웠지만, 그만큼 이 제품을 나의 아이디어로 성장시켰다는 점에서 더 의미가 있었습니다.'
  },
  {
    student_number: 2021151051,
    name: '오기석',
    name_en: 'Ki-Seok Oh',
    sentence: '요즘 날씨 참 좋네, 나 같아',
    email: 'okshope@naver.com',
    instagram: '7l.ssk',
    plant_name: '기싹',
    plant_category: '안개초',
    concept:
      '작품에서 다뤄진 핵심 내용은 매몰 사고 발생 시 구조 대원의 부상을 방지하고 기존의 장비 문제점의 개선입니다.',
    experience:
      '제품을 만들기 위한 리서치를 통해 알게 된 것은 생명을 구하는 사람들의 신변이 위협받는 심각한 주제임에도 불구하고, 이것을 해결하기 위한 연구와 투자가 거의 이루어지지 않고 있었다는 것입니다. 위험성에 대해 무엇보다도 구조 대원 분들 스스로가 가장 잘 알고 있기 때문에 생길 수밖에 없는 불안감과 공포심이 심각한 문제 중 하나였는데요. 이를 덜어줄 수 있는 안전 장비를 고안해 내는 것이 앞서 말한 심적인 고통에서 벗어날 수 있도록 하는 데에 최우선적으로 필요하다고 생각했습니다.',
    challenge:
      '제품을 만들기 위한 리서치를 통해 알게 된 것은 생명을 구하는 사람들의 신변이 위협받는 심각한 주제임에도 불구하고, 이것을 해결하기 위한 연구와 투자가 거의 이루어지지 않고 있었다는 것입니다. 위험성에 대해 무엇보다도 구조 대원 분들 스스로가 가장 잘 알고 있기 때문에 생길 수밖에 없는 불안감과 공포심이 심각한 문제 중 하나였는데요. 이를 덜어줄 수 있는 안전 장비를 고안해 내는 것이 앞서 말한 심적인 고통에서 벗어날 수 있도록 하는 데에 최우선적으로 필요하다고 생각했습니다.',
    story:
      '이제까지 해온 제품 디자인 같은 경우 필수적으로 생각해야 하는 것이 비교적 적었었는데요. 선정한 주제는 인체의 형태, 제품의 무게, 안전의 기능, 기존 장비와의 차별점 등이 고려되어야 했습니다. 이러한 것들을 하나씩, 또는 동시에 해결할 수 있는 구조와 적용 가능성을 스스로 찾아내는 과정과 달성이 제가 할 수 있는 디자인의 폭이 더 넓어진 것이 느껴집니다.'
  },
  {
    student_number: 2021151012,
    name: '김정연',
    name_en: 'Jeong-Yeon Kim',
    sentence: '공감이 담긴 디자인을 위한 첫걸음',
    email: 'kyjmsd@gmail.com',
    plant_name: '라온힐조',
    plant_category: '바질',
    concept:
      '작품의 주요 컨셉은 바람을 닮은 자유입니다. 보행이 불편해 이동의 자유를 잃은 반려견들이 움직이는 자유로움, 더 나아가 장애견을 바라보는 시선으로부터의 자유로움을 제공하고자 디자인하였습니다. 따라서 강아지가 안정적으로 느끼는 설계, 예쁜 외형 디자인을 위해 노력했습니다.',
    experience:
      '작품을 통해 사용자에게 전달하고자 하는 경험은 편안함과 자유로움입니다. 강아지 휠체어를 통해 구매자는 자신의 반려견이 다시 활발하게 움직이고, 자유롭게 활동하는 모습을 보며 기쁨과 만족을 느낄 수 있을것입니다. 이를 통해 우리는 편안함과 자유로움의 가치를 전달하고자 했습니다. ',
    challenge:
      'Aira를 만들면서 가장 큰 도전은 강아지의 편의를 고려하는 것이었습니다. 인간이 아닌 강아지가 제품을 착용했을 때 편안함을 느낄지 알아보기위해 저는 직접 장애견을 찾아가 실험해보는 방법을 사용했습니다. 이러한 노력 덕분에 저는 강아지가 제품을 착용했을 때 가장 편안한 반응을 보이는 결과물을 얻을 수 있었습니다.',
    story:
      'Aira는 점차 발전하는 반려견 시장에서 장애견의 신체적 문제와 같은 외적 문제를 넘어 심리적 문제와 같은 내적 문제까지 고려할 수 있다는 가능성을 제기할 수 있다고 생각합니다. 이는 사용자의 내적 문제, 또는 사용자조차 원한다는 것을 알지 못하는 니즈를 충족시키는 디자인에 대해 경험하게되었습니다. '
  },
  {
    student_number: 2020151019,
    name: '박정빈',
    name_en: 'Jung-Bin Park',
    sentence: 'NOW OR NEVER',
    email: 'jb0201200@naver.com',
    plant_name: '새싹이',
    plant_category: '바질',
    concept:
      '작품의 주요 아이디어나 컨셉은 기울어진 서가입니다. 서가가 기울여 사용자가 도서의 이름 식별과 분류를 더 쉽게 하였고, 기존에 책이 떨어지는 문제도 개선하였습니다. 이런 특징을 중심으로 우리는 도서관 사서가 북카트를 사용할 때 더 편안한 사용성을 제공하기 위해 노력했습니다.',
    experience:
      '작품을 통해 사용자에게 전달하고자 하는 경험은 도서관에서의 더 편안한 배가 경험입니다. 이를 통해 우리는 도서관 사서의 업무 가중을 덜고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 직접 만들어보는 것이었습니다. 스케치나 모델링으로는 완벽한 디자인을 만들기 어려울 것이라고 생각해서 목재를 사서 직접 자르고 나사를 박고 만들어보았습니다. 이러한 노력 덕분에 저희는 제품의 부족한 점을 찾게 되고 직접 개선해 보며 제품에 대한 이해도가 높아졌고 함께 만들면서 팀워크를 잘 맞춰나가는 경험을 할 수 있었습니다.',
    story:
      '저희가 개발한 디자인으로 도서관에서 일하는 분들이 더욱 쉽게 업무를 할 수 있도록 도움을 줄 수 있게 되었습니다. 개발을 하면서 그 제품을 사용하는 사람들에 대해 알아보고 힘든 것은 무엇인지 어떤 부분에 도움을 주어야 하는지를 찾아보며 사용자를 위한 디자인에 대해 더욱 배우게 된 것 같고 팀원과 소통하며 같이 프로젝트를 진행하는 데에 있어서도 협동하고 서로 도와주며 많이 배운 것 같습니다.'
  },
  {
    student_number: 2021151016,
    name: '김채은',
    name_en: 'Chae-Eun Kim',
    sentence: 'be you',
    email: 'omeoul@gmail.com',
    instagram: 'codmso',
    photopolio_link: 'https://cekim-portfolio.framer.website/',
    plant_name: '메롱식물이',
    plant_category: '바질',
    concept:
      '작품의 주요 아이디어나 컨셉은 기울어진 서가입니다. 서가가 기울여 사용자가 도서의 이름 식별과 분류를 더 쉽게 하였고, 기존에 책이 떨어지는 문제도 개선하였습니다. 이런 특징을 중심으로 우리는 도서관 사서가 북카트를 사용할 때 더 편안한 사용성을 제공하기 위해 노력했습니다.',
    experience:
      '작품을 통해 사용자에게 전달하고자 하는 경험은 도서관에서의 더 편안한 배가 경험입니다. 이를 통해 우리는 도서관 사서의 업무 가중을 덜고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 직접 만들어보는 것이었습니다. 스케치나 모델링으로는 완벽한 디자인을 만들기 어려울 것이라고 생각해서 목재를 사서 직접 자르고 나사를 박고 만들어보았습니다. 이러한 노력 덕분에 저희는 제품의 부족한 점을 찾게 되고 직접 개선해 보며 제품에 대한 이해도가 높아졌고 함께 만들면서 팀워크를 잘 맞춰나가는 경험을 할 수 있었습니다.',
    story:
      '저희가 개발한 디자인은 기존 대비 적은 비용으로도 큰 효과를 낼 수 있는 가능성을 열어주었습니다. 이를 통해 저는 두 가지 측면에서 성장했습니다. 첫째, 제품을 기획하고 사용자의 니즈를 파악하는 과정에서 적극적인 면모를 키울 수 있었습니다. 둘째, 공방과의 협업을 통해 생산 과정을 관리하고 제품 형태를 고안하는 데 있어 능동적인 자세를 기를 수 있었습니다.'
  },
  {
    student_number: 2019151036,
    name: '조용현',
    name_en: 'Yong-Hyeon Cho',
    sentence: '부족한 작품이지만 잘 부탁드립니다.',
    email: 'dydgus9130@gmail.com',
    plant_name: '별무늬',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 컨셉은 대형견 유모차가 차량용 켄넬의 기능을 겸비하는 것입니다. 대형견 유모차가 차량용 켄넬의 기능을 겸비하기 위해선 유모차가 대형견을 탑승시킨채 차량에 적재되는 편의성이 필요했고, 이를 달성하기 위해 노력했습니다.',
    experience:
      '유모차가 필요한 대형견이 차량으로 이동할 때 켄넬과 유모차를 따로 구비하지 않아도 동시의 기능을 사용할 수 있는데 중점을 뒀습니다. 이를 통해 한가지 제품 만으로도 두가지 제품의 기능으로 활용할 수 있는 것을 강조하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 큰 대형견 유모차를 차량에 어떻게 실을 지 였습니다. 이를 극복하기 위해 저는 다양한 타 제품을 찾아보거나 주변 사물 혹은 지인들에게 아이디어를 얻는 방법을 사용했습니다. 이러한 노력 덕분에 저는 필요한 기능들을 충족할 수 있는 방식을 찾아 결과물을 이룰 수 있었습니다.',
    story:
      '이번 작품이 제가 재학하는 동안 디자인한 제품 중 가장 큰 사이즈였습니다. 크기가 커지면서 제품의 무게, 중심 등 신경 써야 할 부분이 많아졌고, 제품의 재료, 구조 등이 도움을 줄 수 있다는 것을 실감하게 되었습니다.'
  },
  {
    student_number: 2020151045,
    name: '최민경',
    name_en: 'Min-Kyeong Choe',
    sentence: '앞으로도 힘내자!',
    email: 'lunachoe02@gmail.com',
    plant_name: '콩나물',
    plant_category: '안개초',
    concept:
      '저희의 보조 보행차는 사소한 편리함을 제공합니다. 주 사용층인 노년층 및 신체적으로 불편함을 겪는 사용자들을 고려하여, 기울어진 노면에서도 팔걸이의 각도를 자동으로 평행하게 유지해 편안하게 중심을 잡을 수 있도록 했습니다. 또한, 브레이크의 경우 일반적 브레이크와 반대로 잡으면 움직이고 놓으면 제동이 걸리는 방식으로 제품을 사용하지 않을 시 완전한 브레이크가 걸리도록 하여 안전성을 높였습니다. 이와 같은 설계로 사용자에게 실질적인 도움과 일상 생활을 질을 향상시키기위해 노력했습니다.',
    experience:
      '현재 한국에서는 고령화가 큰 사회적 이슈임에도 불구하고, 그들이 겪는 보행 문제는 여전히 개선되고 있지 않고 있습니다. 저희 작품은 이러한 문제를 해결하고자, 사용자에게 보다 편안하고 안전한 보행 경험을 제공하는 것을 목표로 하고 있습니다. ',
    challenge:
      '기술 구현이 저희에게 가장 큰 도전이었습니다. 저희 과가 공학 기반의 디자인을 지향하지만, 관련 기술을 전문적으로 다루지 않기 때문에, 구상과 실제 구현 사이의 차이를 이번 작품을 통해 많이 느꼈습니다. 이를 극복하기 위해 관련 전문가들의 조언을 지속적으로 구하는 것이 얼마나 중요한지를 깨달았습니다. ',
    story:
      '저희 주제는 제게 있어 일상적으로 지나다니는 노면의 상태와 같은 보행 문제에 대해 좀 더 고찰할 수 있게 해 주었습니다. 또한 개발과정에서 구상과 현실과의 차이로 인한 시행착오를 많이 겪었는데 이를 극복하는 과정에서 문제 해결에 대한 접근방식, 그리고 인내심과 같은 측면에서 성장할 수 있었다고 생각합니다. '
  },
  {
    student_number: 2020151023,
    name: '사공도영',
    name_en: 'Do-Young Sakong',
    sentence: '새로운 꿈을 그리는 사람이 되겠습니다',
    email: 'do-young_design@naver.com',
    instagram: 'do.young_design',
    photopolio_link: 'www.linkedin.com/in/doyoung-design',
    plant_name: '영룩이',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 컨셉은 다이나믹한 경험을 할 수 있는 수상 모빌리티입니다. 다이나믹한 경험은 긍정적이고 문화적인 측면으로 나타날 수 있다고 생각하였습니다. 이를 위해 여러가지 공상과학 영화를 비롯한 다양하게 제작된 수상 모빌리티들에서 아이디어를 얻어 안정적인 경험을 제공할 수 있도록 노력하였습니다. ',
    experience:
      '작품을 통해 전달하고자 하는 메세지와 경험은 자유로움입니다. 비슷한 활동을 진행하게 될 후배들, 타 대학의 유사전공자들 모두 누군가를 돕기위한 주제를 통해 작품을 제작하는 경험이 많아보였고, 그 틀을 깨보는 시도를 통해 다양한 주제를 도전할 수 있는 길을 열어주고자 하였습니다. 그리고 사용자에게도 수중과 수면이라는 공간의 경계를 허물며 더욱 다각도의 경험을 통해 자유로움을 경험할 수 있도록 하는 방법을 제안하고자 하였습니다. ',
    challenge:
      '작품을 만들면서 가장 큰 도전은 상상에서 그치는 것이 아닌, 제품을 현실로 꺼내오는 과정에서오는 한계가 있는 것 이었습니다. 이를 극복하기 위해 저희는 실무에 계신 멘토님을 비롯해, 주변에서 자문을 구할 수 있는 수단과 방법을 가리지 않고 다양한 조언을 얻어가며 작품을 구체화 하는 방법을 선택하였습니다. 이 덕분에 저희는 막막하던 것에 비해 비교적 간단하게나마 실현할 수 있는 방법을 찾아서 제품에 생명을 불어넣을 수 있었습니다.',
    story:
      '방법을 찾고 풀어나가는 방법과, 그 해법을 위해 기술적인 조사를 하는 방법, 그 디자인을 해야하는 이유 등을 찾아가며 막막했던 언덕을 하나씩 넘어왔다는 생각이 들었습니다. 아직도 공부해야 할 것들은 정말 많지만 디자인뿐만이 아닌 설계, 엔지니어링 등 다양한 영역에 대해 고민하고, 전혀 새로운 시각에 눈을 뜰 수 있었던 계기가 되었던 것 같습니다.'
  },
  {
    student_number: 2019151033,
    name: '이서하',
    name_en: 'Seo-Ha Lee',
    sentence: '충실한 오늘',
    email: 'fomular135@gmail.com',
    plant_name: '자라자',
    plant_category: '바질',
    concept:
      '작품의 주요 아이디어나 컨셉은 허리디스크를 앓거나 허리가 약한 사람들이 쇼핑카트에서 짐을 실어나를 때 덜 힘을 들일 수 있게 하는 인체공학적 디자인 입니다. 이런 테마를 중심으로 제  작품은 무거운 것을 들어올릴 때 허리에 가해지는 부담을 줄일 수 있는 방식으로 고려되었습니다.',
    experience:
      '작품을 통해 전달하고자 하는 메시지나 사용자에게 전달하고자 하는 경험은 인체공학/한번 망가지면 되돌리기 힘든 허리 건강을 일상의 소소한 부분에서부터 지키자는 시도 입니다. 또한 허리가 좋지 않은 연령대는 주로 노인이므로, 처음엔 시니어층을 위한 디자인을 하자는 취지에서 시작되기도 했습니다. 이러한 메시지를 통해 우리는 무엇보다 정교한 방식으로 조형된 우리의 허리를 소중이 여기고, 쇼핑을 끝마치고 무거운 짐을 옮길 때 보다 편리하고 가벼운 마음이 드는 경험을 강조하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 쇼핑카트 안에 들어갈 내부 구조 도면을 설계하는 것이었습니다. 또 작품에 꼭 들어가야 할 필수적인 부품 역시 시중에서 사기 힘들어 직접 만들거나 열심히 찾아야 했습니다. 이를 극복하기 위해 저는 많은 자료를 조사하고 정보를 찾아보며 제가 만들고자 하는 구조를 실현시킬 수 있는 대체적인 부품들을 구할 수 있게 되었고, 직접 만든 도면을 3D프린트로 뽑아 조립해볼 수 있었습니다. ',
    story:
      '기존 택배나 물류수송업에서만 찾아볼 수 있었던 리프트 밀차를 소형화하여 가정에서도 사용될 수 있는 물품으로 재해석, 재탄생시켰고, 현대인들의 필수 루틴이 되어버린 쇼핑의 한 부분을  이전보다 편리하게 즐길 수 있는 가능성을 열었다고 생각합니다. 저는 이 작품을 만들며 여러 사례와 자료를 조사했고 그로인해 인사이트를 도출할 수 있는 능력을 길렀습니다.  '
  },
  {
    student_number: 2021151049,
    name: '김도훈',
    name_en: 'Do-Hoon Kim',
    sentence: '2024 졸업전시 화이팅',
    email: 'kimdohoon158@gmail.com',
    instagram: 'dod.___.an',
    plant_name: '말린망고',
    plant_category: '바질',
    concept:
      '요즘 사라졌던 umpc가 게이밍 컨셉으로 다시 부활하였습니다. 하지만 핸드핼드기기인 제품이 아이러니하게도 무거운 무게와 발열과 같은 치명적인 단점을 가지고 있고 성능은 높지않지만 고가의 노트북과 비슷한 가격대를 형성하고 있습니다.많은 사람들이 앞으로 이 게이밍시장이 커질것이라고 예상하는 만큼 이런 단점들을 무선디스플레이 기술을 활용하여 해결하는 새로운 게이밍폼팩터를 만들자 라는 의도에서 시작되었습니다 ',
    experience:
      '작품을 통하여 사용자에게 가장 크게 전달하고자 하는 것은 쾌적한 사용감 이였습니다. 핸들핼드 기기에서 발생하는 무게와 발열을 최소화 하면서 알맞은 그립감을 사용자에게 제공함으로써 쾌적한 사용성을 느끼게 하고 싶었습니다.',
    challenge:
      '이 작품을 진행하기 전에 하려고했던 다른 작품이 있었다. 교수님들에게 조금은 생소한 게이밍이라는것을 졸업작품에 녹여내기가 사실 어려울것이라고 예상은 하였기 때문이고 기술적으로 구현할수 있을지에 대해 우려가 많았었다 그럼에도 이 작품을 진행을 한 이유는 팀원과 내가 이런 제품쪽에 평소 관심이 많았기도 했고 앞으로 이런일을 많이 진행해 보고 싶었기에 포트폴리오에 한번쯤 이런 작품을 올려보는게 충분히 가치가 있을거라고 생각했다. 진행하고 있는 아이디어에 대해서 지속적으로 의문을 품으며 생각하였고 시장성과 사용성에 대해 정말 많이 조사하였다 발표때는  직접 umpc를 가져와서 조사한 시장성과 현재 가지고 있는 단점들에 대해  설명하면서 진행하고 있는 아이디어를 이해하고 설득하는데 노력을 정말 많이 하였다. 가끔 답답한 기분이 들고 작품을 진행하는데에 있어서 회의감이 들기도 하였지만 이런것도 하나의 경험이라고 생각하면서 계속 진행하였다. 그리고 어느정도 아이디어를 이해시키고 설득시키는데 성공하였다. 이런 노력들은 내가 가진 아이디어를 생소하게 생각하는 사람들에게 어떻게 이해시키고 설득시키는지 그리고 어떻게 작품에 녹일수 있는지에 대한 좋은 경험이 되었고 앞으로 진행될 여러 작품에 좋은 자신감을 심어주었다.',
    story:
      '획일화 되고 있는 게이밍기기의 디자인에 새로운 가능성을 열어줄수 있는 디자인이라고 생각한다. 또한 이 제품을 진행하면서 어떻게 사용자에게 더 많은 사용가치를 제공할수 있는 점을 고민하였던 노력들이 기술적인 문제해결 능력을 키우고 협업하는 능력을 키울수 있던 좋은 경험이였다.'
  },
  {
    student_number: 2021151005,
    name: '곽우령',
    name_en: 'Woo-Ryoung Kwak',
    sentence: '힘들어도 그냥 하면 이게 되네...?',
    email: 'dnfud21@gmail.com',
    plant_name: '막냉이',
    plant_category: '얼룩강낭콩',
    concept:
      '안전삼각대는 2차 사고를 방지하기 위해 나온 제품입니다. 그러나 기존 시장에 나와있는 제품들은 삼각대의 2차 사고를 막는데 적합하지 못한 부분이 있어서 저는 그 문제점에 대해 보완하는 제품을 디자인해보고자 했습니다. ',
    experience:
      '도로에서 의도치 않게 정차하게 되었을 때 많은 분들이 당황하여 우왕좌왕하여 나도 모르게 위험에 노출되게 될 수 있는데요. 이때 복잡한 과정없이 삼각대를 설치하여 대처를 빠르게 한다면 나의 안전과 함께 다른 사람들의 안전도 함께 지킬 수 있습니다. 모두의 안전을 지키기 위한 방법은 생각보다 간단할지도 모른다에 대해서 알려드리고 싶습니다. ',
    challenge:
      '작품을 만들면서 가장 큰 도전은 역시 나만의 온전한 아이디어를 구체화시키기였습니다. 사실 이걸 위해서 졸업설계를 혼자 진행한 것이었습니다.  쉽게 타협해버리는 습관을 고치기 위해서 끊임없이 계획을 세우고 하루에 조금씩이라도 하려고 노력했습니다. 그리고 교수님께 피드백을 받아서 자극을 받기 위해 교수님을 조금 귀찮게 굴기도 했습니다. 그런 노력 덕분에 지금 인터뷰 영상을 찍고 있지 않나 생각이 드네요.  ',
    story:
      '사실 제가 사용한 기술은 그렇게 어렵지 않은 기술이에요. 스크린이라던가 장난감이라던가 실생활에서 가깝게 접할 수 있는 간단한 구조를 사용해 제품을 디자인했는데요. 제가 전하고 싶은 것은 우리의 삶을 유용하게 해주는 방법은 멀지 않은 곳에 있다는 거에요. 현재의 기술은 더할 나위 없이 발전해있다고 생각합니다. 디자이너로써 우리가 해야하는 것은 이미 만들어진 조각들을 합쳐 새로운 형태를 고안해내는 것이라고 생각해요. 그래서 저는 이번 졸업설계가 그 새로운 형태를 제안할 수 있는 능력을 한층 성장시켰던 순간이었습니다.'
  },
  {
    student_number: 2019151003,
    name: '김다준',
    name_en: 'Da-Joon Kim',
    sentence: '낙타는 뛸 줄 알지만, 뛰지 않는다',
    email: 'dajoon0417@naver.com',
    instagram: 'give_todo',
    photopolio_link: 'https://www.behance.net/dajoonkim',
    plant_name: '다식이',
    plant_category: '얼룩강낭콩',
    concept:
      "작품의 주요 아이디어는 바다 사막화로 인해 훼손된 생태계에서 자연적인 회복이 어려운 해초를 복원하기 위한 모빌리티를 제작하는 것입니다. 현재 바다 사막화의 확산 추세에 비해 기존 해결책이 미미하여 문제를 더욱 심화시키고 있습니다. 저희는 다이버의 노동력을 줄이고 잘피의 생존율을 높여 바다숲을 조성하기 위해 'SEADER'를 디자인하게 되었습니다.",
    experience:
      '작품을 통해 전달하고자 하는 메시지는 바다 사막화의 심각성과 탄소 포집의 실천을 돕는 것입니다. 스티븐슨에 따르면, 지금처럼 수작업으로 진행할 경우, 발트해의 해조 복구를 위해 다이버 50만 명이 하루에 12시간씩 1년 동안 작업해야 합니다. 저희의 제품으로 바다숲 복원의 효율을 높이고, 더 나아가 캠페인을 기획하고자 합니다. 사람들이 직접적인 참여를 유도해 바다 생태계에 많은 관심을 갖고자 하는 것이 목표입니다.',
    challenge:
      '저희는 타과랑 함께 작품을 제작하는 것이 큰 도전이었어요. 일단 타과랑 같이 하나의 프로젝트를 진행한 경험이 많이 없었고, 친하지도 않은 사람들이라 걱정이 많았죠. 이를 극복했다기보다는 각자 자신 맡은 역할에 최선을 다해 큰 어려움은 없었습니다. 대신 디자인에서 끝나는 게 아니라는 걸 느꼈어요. 저희가 제품을 디자인할 때 구현이 가능한 구조를 설계하려고 하지만, 실제 구현에 맞지 않을 수 있잖아요. 그런 서로가 부족한 점을 채워나갈 수 있었죠. 콘셉트와 디자인만 보여주는 제품이 아니라 실제 구현 가능성을 보여줄 수 있어서 저희 제품에 더 자신할 수 있게 된 것 같아요.',
    story:
      '4년의 배움이 디자인이었다는 사실은 절대 후회없어요. 디자인을 배우며 겪어왔던 모든 일들이 의미가 있었거든요. 프로젝트를 진행하며 디자인을 한 경험을 생각해 보면 기획부터 설계, 제작까지 다 디자인 영역 안에 들어가더라고요. 덕분에 제 자신의 한계가 많이 허물어졌다고 생각해요. 뭐든 요령을 알게 되면 쉽게 접근할 수 있잖아요. 디자인이 저에겐 삶의 요령이 되어줬다고 생각합니다.'
  },
  {
    student_number: 2021151038,
    name: '이예빈',
    name_en: 'Ye-Bin Lee',
    sentence: '공감을 통해 디자인하는 대학생입니다',
    email: 'youmylife41@naver.com',
    instagram: 'yeppi_n',
    plant_name: '안나',
    plant_category: '안개초',
    concept:
      '제 작품의 주요 특징은 소화기에 방패를 결합시킨 것인데요, 사용자가 소화를 하는 과정에 생길 수 있는 화상을 방지함으로써 사용자에게 안전함을 느끼게 할 수 있도록 소화기를 리디자인하였습니다. 또한 소화기는 소방법상 소화기가 배치된 곳에 40cm 이내에 소화기 안내 표지판을 벽에 붙여야 하는데요, 벽에 스티커를 붙이거나, 나사로 벽에 고정하게 되면 공공장소의 경우는 공간의 자율성이 떨어지고, 시각적으로도 효과성이 떨어집니다. 이러한 소화기 안내판을 방패 자체에 붙여버림으로써 공간의 자율성을 높일 뿐만 아니라 법의 문제도 함께 해결할 수 있습니다.',
    experience:
      '저는 이 소화기를 통해 사용자가 소화기를 사용할때 화상이나 불 자체에 대한 두려움을 가지지 않고 사용했으면 좋겠다는 바램으로 제품을 기획하였습니다.  결론적으로 방패형 소화기라는 아이디어를 통해 소화기를 사용해야하는 사용자에게는 화상의 두려움을 없애고 적극적으로 화재 소화에 나서는 경험을, 그리고 공공장소에 소화기를 거치해야하는 사용자에게는 공간의 자율성과 법률적인 해결을 강조하고자 하였습니다.',
    challenge:
      '작품을 만들면서 큰 도전은 방패를 펼치는 형식을 고려하면서도 소화기의 무게나 작동 방식을 고려하는 것이었습니다. 여러 가지 고려해야 할 요소들이 많았는데 저는 교수님의 면담과 선배님들의 조언이 제게 큰 도움이 되었습니다. 제가 생각하지 못했던 고려 사항이나 문제들을 3자의 관점에선 쉽게 보일 수 있기 때문에 문제점을 파악하는데 더 빠르게 수용할 수 있었습니다. 이를 통해 결과적으로 사용자가 소화기의 무게를 견딜 수 있는 방패형 소화기를 제작할 수 있었습니다. ',
    story:
      '저는 이 졸업작품을 통해 제품을 아이디어와 렌더링에서 끝내는 게 아니라 어떻게 작동을 시켜야 할지, 제작 과정에서 오류사항은 없는지 꼼꼼하게 확인하는 태도를 가질 수 있었습니다 이론으로만 배웠던 사용자를 위한 디자인을 저의 작품으로 직접 제작하며 고려함으로써 디자인에서 공감이 얼마나 중요한지 깨달을 수 있었던 아주 소중한 경험이었습니다.'
  },
  {
    student_number: 2019151045,
    name: '황민성',
    name_en: 'Min-Seong Hwang',
    sentence: '기회는 직접 찾는 거야',
    email: 'rkd0206tjs@naver.com',
    instagram: 'mnsng_00',
    plant_name: '캐서린',
    plant_category: '안개초',
    concept:
      '주요 컨셉은 재활의지를 돕기 위해 산책과 재활을 합쳤다는 것입니다. 관절 구축이란 쉽게 말해서 관절을 사용하지 않아 굳는 질병인데, 스스로 거동이 불가하거나, 침상 생활을 오래 하는 경우 관절 구축이 발생합니다. 때문에 주기적으로 관절을 움직여야 하는데,  스스로 움직일 수 없는 경우엔 요양사나 보호자가 수동운동을 해줘야 합니다. 이때 자의로 움직일 수 없는 신체를 누군가 운동시켜준다는 게 재활 환자 입장에서 썩 유쾌하지 않겠다는 생각을 했습니다. 하고 싶은 것을 할 때 하기 싫은 것까지 해결된다면 좋지 않을까? 하는 아이디어로 시작되었습니다.',
    experience:
      '재활은 의지가 매우 중요합니다. 그래서 조금이라도 즐겁게 재활을 했으면 하는 마음으로 디자인하였습니다. 사람들은 자전거를 보면 당연히 페달을 밟아 바퀴를 굴리는 생각을 합니다. 재활환자가 제품을 통해 이와 비슷한 행위를 하면서 내가 스스로 힘주어 앞으로 나아가는 기분을 느꼈으면 합니다.',
    challenge:
      '조향에 관련하여 고민을 많이 했습니다. 바퀴가 하나인 디자인으로 전개를 해오다가 조향을 위해서 바퀴 하나를 더 추가했고 그 과정에서 디자인 전체가 바뀌어서 처음부터 다시 시작하게 된 시점이 있는데 그때 밤을 자주 새면서 체력적으로 굉장히 힘들었습니다. 커피와 음악이 큰 도움이 되었습니다.',
    story:
      '공학설계부터 지금까지 약 1년 동안 같은 주제로 프로젝트를 진행했습니다. 이렇게 장기적으로 진행한 게 처음이어서 그런지 중간에 너무 풀어지는 때도 있었고 아쉬운 부분이 많이 있는 것 같습니다. 꾸준한 게 가장 빠르다는 것을 깨닫게 된 것 같습니다.'
  },
  {
    student_number: 2019151039,
    name: '최성일',
    name_en: 'Seong-Il Choe',
    sentence: '긍정적인 영향력을 더 멀리 멀리',
    email: 'sungil3816@gmail.com',
    instagram: 'sungil3816',
    plant_name: '계란보끔밥',
    plant_category: '안개초',
    concept:
      '작품의 주요 아이디어는 건축 현장에서 쓰고 남은 목재나 행사장에서 사용하는 현수막 등 우리 주변에서 쉽게 사용되고 버려지는 재료들을 업사이클링한 조립식 가구입니다. 가공 과정에서 발생하는 쓰레기 또한 최소화하기 위한 전개 구조를 설계했고, 총 5개의 파트를 이용하여 영유아용 침대와 식탁·의자 2가지 형태로 만들 수 있습니다.',
    experience:
      '현재 자원 낭비로 인한 환경문제가 심각한데, 미약하지만 이러한 문제를 개선하는 방향성을 제시하여 지속가능한 디자인이 더욱 주목받는 계기가 되었으면 합니다. 그리고 제작한 제품을 아이 케어를 위한 제품이 부족한 지역에 보급하여 영유아의 성장환경을 개선할 수 있다면 또 다른 가치를 만들어 낼 수 있는 기회이기 때문에 차후에 그러한 시스템을 구축해보는 것도 계획하고 있습니다.',
    challenge:
      '제품디자인을 진행하면서 저희가 설정한 맥락의 제약이 너무 많았습니다. 형태는 단순해야 하고, 2가지 기능이 가능해야 하고, 조립이 쉬워야 하고, 아이가 위험하지 않아야 하고, 제작 시 버리는 공간을 최소화해야 했습니다. 이 때문에 맥락 개연성과 형태를 수정하는 작업이 정말 많았습니다. 그 모든 과정이 헛되진 않았다고 생각하지만, 당시에는 바뀔 때마다 참 힘들었던 기억이 나네요. 그래도 이러한 노력 덕분에 저희의 최종 컨셉이 잘 잡혀서 좋습니다.',
    story:
      '당연하다고 생각한 것들이 이뤄지지 않아 많은 사람들이 고통받고 있다는 것을 알게 되었고, 이러한 문제를 해결하는데 대단한 기술이 아닌 간단한 무언가로 해결된다는 것을 느꼈습니다. 앞으로 디자인 학문을 깊이 탐구하여 디자인을 통해 일상생활 속 작은 문제부터 세계 어딘가에서 고통받고 있을 사람들의 문제까지 해결하여 보다 나은 삶을 제공해주고 싶습니다.'
  },
  {
    student_number: 2019151005,
    name: '김두언',
    name_en: 'Du-Eon Kim',
    sentence: '더디어도 확실하게 차곡차곡 ',
    email: 'igniter7@naver.com',
    instagram: 'uoanp._',
    plant_name: '둥둥이',
    plant_category: '안개초',
    concept:
      '작품의 주요 아이디어는 건축 현장에서 쓰고 남은 목재나 행사장에서 사용하는 현수막 등 우리 주변에서 쉽게 사용되고 버려지는 재료들을 업사이클링한 조립식 가구입니다. 가공 과정에서 발생하는 쓰레기 또한 최소화하기 위한 전개 구조를 설계했고, 총 5개의 파트를 이용하여 영유아용 침대와 식탁·의자 2가지 형태로 만들 수 있습니다.',
    experience:
      '현재 자원 낭비로 인한 환경문제가 심각한데, 미약하지만 이러한 문제를 개선하는 방향성을 제시하여 지속가능한 디자인이 더욱 주목받는 계기가 되었으면 합니다. 그리고 제작한 제품을 아이 케어를 위한 제품이 부족한 지역에 보급하여 영유아의 성장환경을 개선할 수 있다면 또 다른 가치를 만들어 낼 수 있는 기회이기 때문에 차후에 그러한 시스템을 구축해보는 것도 계획하고 있습니다.',
    challenge:
      '제품디자인을 진행하면서 저희가 설정한 맥락의 제약이 너무 많았습니다. 형태는 단순해야 하고, 2가지 기능이 가능해야 하고, 조립이 쉬워야 하고, 아이가 위험하지 않아야 하고, 제작 시 버리는 공간을 최소화해야 했습니다. 이 때문에 맥락 개연성과 형태를 수정하는 작업이 정말 많았습니다. 그 모든 과정이 헛되진 않았다고 생각하지만, 당시에는 바뀔 때마다 참 힘들었던 기억이 나네요. 그래도 이러한 노력 덕분에 저희의 최종 컨셉이 잘 잡혀서 좋습니다.',
    story:
      '개인적으로 세상에 나올 제품은 이미 다 나왔다고 생각하기 때문에, 우리 디자이너가 고민하고 나아가야 할 방향성에 대해 고민해보게 됩니다. 그 길 중 하나가 업사이클링 기술을 더 최적화해서 사용하는 방안이라고 생각했습니다. 사람과 환경 모두를 배려하는 디자인을 계속해서 연구해보고 싶습니다.'
  },
  {
    student_number: 2018151023,
    name: '원윤섭',
    name_en: 'Yoon-Seop Won',
    sentence: '역경을 헤치고 별을 향하여',
    email: 'yoonseop369@gmail.com',
    plant_name: '콩이',
    plant_category: '얼룩강낭콩',
    concept:
      '작품의 주요 컨셉은 매일 세탁하기 힘든 헬멧을 보다 간편하게 살균하기 위해 테이블 위의 오브제 역할을 할 수 있는 살균기라는 테마를 중심으로 매일 위생적인 헬멧을 사용하고자 하는 목적을 달성하기 위해 노력했습니다.',
    experience:
      '작품을 통해 전달하고자 하는 경험은 살균기를 사용한 효율성과 위생입니다. 간편한 거치식 헬멧 살균기를 통해 매일 사용하는 헬멧을 보다 쾌적하게 사용하는 경험을 강조하고자 했습니다. 또한 살균기능을 수행하지 않을 때에도 하나의 오브제로서의 역할을 수행하고자 했습니다.',
    challenge:
      '작품을 만들면서 가장 큰 도전은 효율적이고 일정한 살균 영역 배치얐습니다. 이를 극복하기 위해 다양한 거치대 방식의 목업을 만들어 비교해보며 이상적인 형태를 찾기 위해 노력했습니다. 이러한 노력 덕분에 저는 돔 형태의 램프 상부 모양을 도출해낼 수 있었습니다.',
    story:
      '저의 디자인을 통해 퍼스널 모빌리티의 성장 추세에 따른 관련 악세사리 제품 시장의 성장 가능성을 보았습니다. 이러한 디자인 활동을 통해 시장을 조금 더 넓게 볼 수 있는 안목을 키울 수 있었습니다.'
  }
];
